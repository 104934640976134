import React, { useContext } from "react"
import styled from "styled-components"

import { Button } from "components/Button"
import { Store } from "store"
import { verifyTokenRole } from "config/tokenRouter"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  width: 30rem;

  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    color: ${props => props.theme.palette.gray[600]};
  }
`

export default function({ history }) {
  const [state] = useContext(Store)
  const { token } = state

  const handleRetry = () => {
    return verifyTokenRole(token.alternate, history)
  }

  return (
    <Container>
      <h4>Não foi possível validar o liveness.</h4>
      <Button onClick={handleRetry}>Tente Novamente</Button>
    </Container>
  )
}
