import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import Webcam from 'react-webcam'
import CircularProgress from '@material-ui/core/CircularProgress'
import jwtDecode from 'jwt-decode'

import Container from './styles'
import { getFullFaceDescription } from 'api/face'
import { image64toCanvasRef } from 'utils/imageFns'
import { Store } from 'store'
import { postFile, get } from 'api/api'
import { verifyTokenRole } from 'config/tokenRouter'
import { SET_TOKEN } from 'store/reducers/tokenReducer'
import { roles } from 'config/roles'
import { Button } from 'components/Button'

let livenessToken = null

export default function ({ history }) {
  const cameraRef = useRef()
  const canvasRef = useRef(null)
  const [detectMessage, setDetectMessage] = useState(
    'Iniciando detecção facial',
  )
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [frameIndex, setFrameIndex] = useState(0)
  const [state, dispatch] = useContext(Store)
  const { token, callback } = state
  const [cameraStarted, setCameraStarted] = useState(false)
  const [validFace, setValidFace] = useState(false)
  const [showCamera, setShowCamera] = useState(false)

  const getFrame = useCallback(
    async (screenshot, faceBox) => {
      const canvas = canvasRef.current
      let formData = new FormData()
      let file = null

      await image64toCanvasRef(canvasRef.current, screenshot, faceBox)
      const croppedImage = canvas.toDataURL('image/jpeg', 0.9)

      await fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], `liveness_photo.jpeg`, {
            type: 'image/jpeg',
          })
          formData.append('file', file)
        })

      setLoading(true)

      const res = await postFile({
        url: '/liveness',
        token: token.value,
        data: formData,
        history,
      })

      if (res) {
        const { identity } = jwtDecode(res.token)

        if (identity.role !== roles.CAN_SEND_LIVENESS) {
          dispatch({ type: SET_TOKEN, payload: res.token })

          if (callback.url) {
            let url = callback.url
            if (callback.url.indexOf('?') > -1)
              url = `${callback.url}&token=${res.token}`
            else url = `${callback.url}?token=${res.token}`
            window.location.replace(url)
          } else {
            return verifyTokenRole(res.token, history)
          }
        } else {
          setFrameIndex(frameIndex + 1)
        }
      }
    },
    [callback, dispatch, frameIndex, history, token.value],
  )

  const findFace = useCallback(async () => {
    return new Promise(async (resolve) => {
      if (cameraRef.current && cameraRef.current.getScreenshot()) {
        const screenshot = cameraRef.current.getScreenshot()
        await getFullFaceDescription(screenshot).then(async (fullDesc) => {
          if (fullDesc) {
            setValidFace(true)
            getFrame(screenshot, fullDesc._box)
            resolve(true)
          }

          resolve(false)
        })
      } else {
        setValidFace(false)
        resolve(false)
      }
    })
  }, [getFrame])

  const main = useCallback(async () => {
    if (cameraRef.current && cameraRef.current.getScreenshot()) {
      await getFullFaceDescription(cameraRef.current.getScreenshot()).then(
        async (fullDesc) => {
          if (fullDesc) {
            const face_box = fullDesc._box

            if (face_box._width < 430) {
              setValidFace(false)
              setDetectMessage('Aproxime seu rosto até o círculo ficar VERDE')
              setTimeout(main, 300)
            } else if (face_box._width > 530) {
              setValidFace(false)
              setDetectMessage('Afaste seu rosto')
              setTimeout(main, 300)
            } else {
              setDetectMessage('Aguarde...')
              setValidFace(true)

              setTimeout(async () => {
                let descFound = false

                while (!descFound) {
                  descFound = await findFace()
                }
              }, 1000)
            }
          } else {
            setValidFace(false)
            setDetectMessage('Aguarde, detectando seu rosto...')
            setTimeout(main, 300)
          }
        },
      )
    } else {
      setValidFace(false)
      setDetectMessage('Aguarde, detectando seu rosto...')
      setTimeout(main, 300)
    }
  }, [findFace])

  const handleStart = async () => {
    setLoading(true)

    const res = await get({
      url: '/liveness',
      token: token.value,
      history,
    })

    if (res) {
      setShowCamera(true)
      setLoading(false)
      livenessToken = res.token
    }
  }

  const handleCameraStart = () => {
    setCameraStarted(true)
    main()
  }

  return (
    <Container>
      {!showCamera && (
        <div className="information">
          <h3 className="information__title">
            Para garantir o melhor resultado
          </h3>

          <ul className="information__list">
            <li>
              Levante a câmera até o nível dos seus olhos e mantenha seu rosto
              dentro da área marcada
            </li>
            <li>
              Escolha um local com boa iluminação e mantenha seu rosto
              totalmente visível. Evite pontos fortes de luz, como um lâmpada ou
              sol atrás de você
            </li>
            <li>Retire qualquer acessório, como óculos e chapéus</li>
          </ul>

          <div className="information__button_container">
            <Button onClick={handleStart} loading={loading}>
              Iniciar
            </Button>
          </div>
        </div>
      )}

      {showCamera && (
        <div className="cameraContainer">
          <Webcam
            audio={false}
            ref={cameraRef}
            minScreenshotHeight={700}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: 'user' }}
            onUserMedia={handleCameraStart}
          />
          <canvas ref={canvasRef} />
          {cameraStarted && (
            <React.Fragment>
              <div className={`cameraOverlay ${!detectMessage ? 'off' : ''}`}>
                <span className="statusMessage">{detectMessage}</span>
              </div>
              <div className={`loadingOverlay ${loading ? 'on' : ''}`}>
                <CircularProgress className="loadingLoader" />
              </div>
              <div className={`mask ${validFace ? 'valid' : ''}`} />
              <div className="progress" style={{ width: `${progress}%` }} />
            </React.Fragment>
          )}
        </div>
      )}
    </Container>
  )

  // return (
  //   <Container>
  //     <div className="cameraContainer">
  //       {cameraStarted && (
  //         <React.Fragment>
  //           <div className={`cameraOverlay ${!detectMessage ? 'off' : ''}`}>
  //             <span className="statusMessage">{detectMessage}</span>
  //           </div>
  //           <div className={`loadingOverlay ${loading ? 'on' : ''}`}>
  //             <CircularProgress className="loadingLoader" />
  //           </div>
  //           <div className={`mask ${validFace ? 'valid' : ''}`} />
  //           <div className="progress" style={{ width: `${progress}%` }} />
  //         </React.Fragment>
  //       )}
  //       <Webcam
  //         audio={false}
  //         ref={cameraRef}
  //         minScreenshotHeight={700}
  //         screenshotFormat="image/jpeg"
  //         videoConstraints={{ facingMode: 'user' }}
  //         onUserMedia={handleCameraStart}
  //       />
  //       <canvas ref={canvasRef} id="canvas"></canvas>
  //     </div>
  //   </Container>
  // )
}
