import produce from "immer"

import localEnv from "config/env.js"

const { localStorageKey } = localEnv

export const SET_FIELD = "SET_FIELD"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

const loginReducer = (draft, { type, payload }) => {
  switch (type) {
    case SET_FIELD:
      draft[payload.field] = payload.value
      return
    case LOGIN:
      draft["token"] = payload
      localStorage.setItem(localStorageKey, payload)
      return
    case LOGOUT:
      draft["token"] = ""
      localStorage.removeItem(localStorageKey)
      return
    default:
      return
  }
}

export default produce(loginReducer)
