import React, { useContext } from "react"
import styled from "styled-components"

import { Button } from "components/Button"
import { Store } from "store"
import { verifyTokenRole } from "config/tokenRouter"
import { SET_TOKEN } from "store/reducers/tokenReducer"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
`

export default function({ history }) {
  const [state, dispatch] = useContext(Store)
  const { token } = state

  const handleRetry = () => {
    dispatch({ type: SET_TOKEN, payload: token.alternate })
    return verifyTokenRole(token.alternate, history)
  }

  return (
    <Container>
      <h4>Não foi possível validar o quiz.</h4>
      <Button onClick={handleRetry}>Tente Novamente</Button>
    </Container>
  )
}
