import React, { useContext } from "react"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import Input from "@material-ui/core/Input"
import InputMask from "react-input-mask"
import styled from "styled-components"

import { Store } from "store"
import { SET_FIELD } from "store/reducers/fieldsReducer"

const StyledFormHelperText = styled(FormHelperText)`
  text-align: right !important;
`

const masks = {
  cpf: "999.999.999-99",
  phone: "(99) 99999-9999",
  cep: "99999-999"
}

export const MaskedInput = props => {
  const [, dispatch] = useContext(Store)

  const { label, field, form } = props
  const { errors, touched, setFieldValue } = form
  const { name } = field

  function handleChange(value) {
    const newValue = value.replace(/[^\w]/gi, "")
    dispatch({ type: SET_FIELD, payload: { field: name, value: newValue } })
    setFieldValue(name, newValue)
  }

  return (
    <FormControl error={touched[name] && !!errors[name]} fullWidth>
      <InputLabel htmlFor="component-error">{label}</InputLabel>
      <InputMask mask={masks[name]} maskChar="" {...props} {...field} onChange={e => handleChange(e.target.value)}>
        {inputProps => <Input {...inputProps} />}
      </InputMask>
      <StyledFormHelperText id="component-error-text">{touched[name] && errors[name]}</StyledFormHelperText>
    </FormControl>
  )
}
