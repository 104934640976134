import React from "react";
import { Route } from "react-router-dom";

import Container from "./styles";
import logo from "assets/logo.svg";
import { Store } from "store";

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <header>
            <img src={logo} alt="InovaMind" className="logo" />
          </header>
          <Store.Consumer>
            {(context) => <Component {...matchProps} context={context} />}
          </Store.Consumer>
        </Container>
      )}
    />
  );
};
