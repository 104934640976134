import React, { useEffect, useState, useContext, useCallback, useRef } from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import CircularProgress from "@material-ui/core/CircularProgress"

import Container from "./styles"
import { get, post } from "api/api"
import { Store } from "store"
import { SET_TOKEN, SET_ALTERNATE_TOKEN } from "store/reducers/tokenReducer"
import { Button } from "components/Button"
import { verifyTokenRole } from "config/tokenRouter"

export default props => {
  const [{ token }, dispatch] = useContext(Store)
  const [answerIndex, setAnswerIndex] = useState(0)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)
  const [retryToken, setRetryToken] = useState(null)
  const [firstToken, setFirstToken] = useState(false)
  const [timeoutDelay, setTimeoutDelay] = useState(0)
  const [quizToken, setQuizToken] = useState("")

  const [count, setCount] = useState(0)

  const questionTimeoutRef = useRef()

  const prepareQuestions = questions => {
    const newQuestions = questions.map(item => {
      const question = { title: item.title }
      question.answers = item.answers.map(answer => {
        return { title: answer, checked: false }
      })
      return question
    })

    setQuestions(newQuestions)
  }

  const failByTimeout = useCallback(async () => {
    const res = await get({
      url: "/quiz/timeout",
      token: token.value
    })
    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      return verifyTokenRole(res.token, props.history)
    }
  }, [dispatch, props.history, token.value])

  const handleQuestionTimeout = useCallback(() => {
    setCount(timeoutDelay)
    clearTimeout(questionTimeoutRef.current)
    setTimeoutDelay(timeoutDelay)
    questionTimeoutRef.current = setTimeout(failByTimeout, timeoutDelay * 1000)
  }, [failByTimeout, timeoutDelay])

  useEffect(() => {
    if (timeoutDelay > 0) handleQuestionTimeout()
    return () => clearTimeout(questionTimeoutRef.current)
  }, [handleQuestionTimeout, timeoutDelay])

  useEffect(() => {
    if (timeoutDelay > 0) {
      let interval = null
      if (count > 0) {
        interval = setInterval(() => {
          setCount(count => count - 1)
        }, 1000)
      } else {
        setCount(timeoutDelay)
        clearInterval(interval)
      }
      return () => clearInterval(interval)
    }
  }, [count, timeoutDelay])

  const fetchQuestions = useCallback(async () => {
    const res = await get({
      url: "/quiz",
      token: token.value
    })

    setShowQuestions(true)
    setQuizToken(token.value)

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      prepareQuestions(res.questions)
      setTimeoutDelay(res.time_seconds)
    }
  }, [dispatch, token.value])

  useEffect(() => {
    if (!firstToken) {
      dispatch({ type: SET_ALTERNATE_TOKEN, payload: token.value })
      setFirstToken(true)
    }
  }, [dispatch, firstToken, token.value])

  useEffect(() => {
    if (!showQuestions) {
      fetchQuestions()
    }
  }, [fetchQuestions, showQuestions])

  const handleNext = () => {
    let newAnswers = answers
    const currentQuestion = questions[answerIndex]
    const selected = currentQuestion.answers.find(item => item.checked)

    if (selected) {
      newAnswers[answerIndex] = selected.title
      setAnswers(newAnswers)
      setError("")
      setAnswerIndex(answerIndex + 1)

      handleQuestionTimeout()
    } else {
      return setError("Selecione uma resposta")
    }
  }

  const handleSubmit = async () => {
    let newAnswers = answers
    const currentQuestion = questions[answerIndex]
    const selected = currentQuestion.answers.find(item => item.checked)

    if (selected) {
      newAnswers[answerIndex] = selected.title
      setAnswers(newAnswers)
      setError("")
    } else {
      return setError("Selecione uma resposta")
    }

    setLoading(true)
    let correctToken = quizToken
    if (retryToken) correctToken = retryToken
    clearTimeout(questionTimeoutRef.current)

    const res = await post({
      url: "/quiz",
      token: correctToken,
      data: { answers }
    })

    if (res) {
      if (res.questions.length > 0) {
        setAnswers([])
        setRetryToken(res.token)
        setAnswerIndex(0)
        prepareQuestions(res.questions)
        setLoading(false)
      } else {
        dispatch({ type: SET_TOKEN, payload: res.token })
        return verifyTokenRole(res.token, props.history)
      }
    } else {
      setLoading(false)
    }
  }

  const toggleChecked = (questionIndex, answerIndex) => {
    const updatedQuestions = questions.map((quest, index) => {
      if (questionIndex === index) {
        quest.answers.map((ans, index) => {
          ans.checked = false
          if (answerIndex === index) {
            ans.checked = !ans.checked
          }
          return ans
        })
      }
      return quest
    })

    setQuestions(updatedQuestions)
  }

  return (
    <Container>
      <h1>Quiz</h1>

      {questions.length > 0 && showQuestions ? (
        <div className="question">
          <h4>{questions[answerIndex].title}</h4>
          <div className="counter">{count}</div>
          <RadioGroup>
            {questions[answerIndex].answers.map((answer, index) => (
              <FormControlLabel key={index} value={answer.title} label={answer.title} checked={answer.checked} onClick={() => toggleChecked(answerIndex, index)} control={<Radio />} />
            ))}
          </RadioGroup>

          {error && <div className="error">{error}</div>}

          <div className="buttonContainer">
            {answerIndex + 1 < questions.length ? (
              <Button onClick={handleNext}>Próxima</Button>
            ) : (
              <Button onClick={handleSubmit} loading={loading}>
                Finalizar
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="loaderContainer">
          <CircularProgress className="loader" />
        </div>
      )}
    </Container>
  )
}
