import React, { useContext, useState } from "react"
import * as Yup from "yup"
import { Formik, Field } from "formik"
import CircularProgress from "@material-ui/core/CircularProgress"

import Container from "./styles"
import { Button } from "components/Button"
import { MaskedInput } from "components/MaskedInput"
import { TextInput } from "components/TextInput"
import { validationMessages } from "utils/messages"
import { Store } from "store"
import { get, post } from "api/api"
import { SET_FIELD } from "store/reducers/fieldsReducer"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { verifyTokenRole } from "config/tokenRouter"

const validationSchema = Yup.object().shape({
  cep: Yup.string().required(validationMessages.required),
  address: Yup.string().required(validationMessages.required),
  number: Yup.string().required(validationMessages.required),
  neighborhood: Yup.string().required(validationMessages.required),
  city: Yup.string().required(validationMessages.required),
  uf: Yup.string().required(validationMessages.required)
})

export default props => {
  const [state, dispatch] = useContext(Store)
  const { token, address } = state
  const [loading, setLoading] = useState(false)
  const [cepLoading, setCepLoading] = useState(false)

  const initialValues = {
    cep: address.cep,
    address: address.address,
    number: address.number,
    complement: address.complement,
    neighborhood: address.neighborhood,
    city: address.city,
    uf: address.uf
  }

  let setFieldValue = null

  const handleSubmit = async () => {
    setLoading(true)

    const res = await post({
      url: `/address/${address.cep}`,
      token: token.value,
      data: {
        cep: address.cep,
        address: address.address,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        city: address.city,
        uf: address.uf,
        type: ""
      }
    })

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      return verifyTokenRole(res.token, props.history)
    }

    setLoading(false)
  }

  const handleCEP = async () => {
    if (address.cep.length === 8) {
      setCepLoading(true)
      const res = await get({
        url: `/address/${address.cep}`,
        token: token.value
      })

      if (res) {
        const { neighborhood, city, uf, address } = res.cep
        dispatch({ type: SET_TOKEN, payload: res.token })
        if (address) dispatch({ type: SET_FIELD, payload: { field: "address", value: address } })
        if (neighborhood) dispatch({ type: SET_FIELD, payload: { field: "neighborhood", value: neighborhood } })
        dispatch({ type: SET_FIELD, payload: { field: "city", value: city } })
        dispatch({ type: SET_FIELD, payload: { field: "uf", value: uf } })

        if (address) setFieldValue("address", address)
        if (neighborhood) setFieldValue("neighborhood", neighborhood)
        setFieldValue("city", city)
        setFieldValue("uf", uf)
      }

      setCepLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Container>
        <h1>Informações Adicionais</h1>
        <h4>Preencha seu endereço</h4>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={form => {
            setFieldValue = form.setFieldValue
            return (
              <form onSubmit={form.handleSubmit}>
                <Field name="cep" label="CEP" component={MaskedInput} onKeyUp={handleCEP} />
                <Field name="address" label="Endereço" component={TextInput} />
                <Field name="number" label="Número" component={TextInput} />
                <Field name="complement" label="Complemento" component={TextInput} />
                <Field name="neighborhood" label="Bairro" component={TextInput} />
                <Field name="city" label="Cidade" component={TextInput} readOnly />
                <Field name="uf" label="Estado" component={TextInput} readOnly />
                <Button type="submit" loading={loading}>
                  Confirmar
                </Button>

                {cepLoading && (
                  <div className="cepOverlay">
                    <CircularProgress className="cepOverlayLoader" />
                  </div>
                )}
              </form>
            )
          }}
        />
      </Container>
    </React.Fragment>
  )
}
