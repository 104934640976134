import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { roles } from 'config/roles'

export const verifyTokenRole = (token, history) => {
  if (token) {
    const { identity } = jwtDecode(token)

    switch (identity.role) {
      case roles.CAN_INSERT_SMS_TOKEN:
        return history.push('/confirmation')
      case roles.CAN_CONSULT_CEP:
        return history.push('/address')
      case roles.CAN_SEND_DOC:
        return history.push('/document')
      case roles.CAN_SEND_DOC_FRONT:
        return history.push('/docfront')
      case roles.CAN_SEND_DOC_BACK:
        return history.push('/docback')
      case roles.CAN_SEND_ADDRESS_DOC:
        return history.push('/proof')
      // case roles.CAN_SEND_FIRST_LIVENESS_FRAME:
      //   return history.push('/getLiveness')
      // case roles.CAN_SEND_LIVENESS:
      //   return history.push('/liveness')
      case roles.CAN_SEND_FIRST_LIVENESS_FRAME:
        return history.push('/liveness')
      case roles.CAN_RETRY_LIVENESS:
        return history.push('/retryLiveness')
      case roles.CAN_GET_QUIZ:
        return history.push('/quiz')
      case roles.CAN_RETRY_QUIZ:
        return history.push('/retryQuiz')
      case roles.CAN_NOT_COMPLETE:
        return history.push('/end')
      case roles.CAN_COMPLETE:
        return history.push('/success')
      default:
        return history.push('/')
    }
  } else {
    return toast.error('Token inválido')
  }
}
