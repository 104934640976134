import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { roles } from 'config/roles'

import DefaultContainer from 'containers/Default'
import SignedInContainer from 'containers/SignedIn'
import LivenessContainer from 'containers/Liveness'
import FullSizeContainer from 'containers/FullSize'

import Init from 'pages/Init'
import Home from 'pages/Home'
import Confirmation from 'pages/Confirmation'
import Address from 'pages/Address'
import DocFront from 'pages/DocFront'
import DocBack from 'pages/DocBack'
import Proof from 'pages/Proof'
import Success from 'pages/Success'
// import GetLiveness from 'pages/GetLiveness'
import Liveness from 'pages/Liveness'
import RetryLiveness from 'pages/RetryLiveness'
import Quiz from 'pages/Quiz'
import RetryQuiz from 'pages/RetryQuiz'
import End from 'pages/End'
import CameraException from 'pages/Exceptions/Camera'

import Document from 'pages/Document'

import AuthRoute from './AuthRoute'
import Login from 'pages/Login'
import Dashboard from 'pages/Dashboard'

export default () => {
  return (
    <Router>
      <Route
        render={() => (
          <div className="content">
            <TransitionGroup component="div">
              <CSSTransition timeout={250} classNames="fade">
                <Switch>
                  <Route exact path="/" component={Login} />

                  <AuthRoute
                    path="/init"
                    parent={SignedInContainer}
                    child={Init}
                  />
                  <AuthRoute
                    path="/dashboard"
                    parent={SignedInContainer}
                    child={Dashboard}
                  />

                  <AuthRoute
                    path="/home"
                    parent={DefaultContainer}
                    component={Home}
                  />
                  <AuthRoute
                    path="/confirmation"
                    parent={DefaultContainer}
                    child={Confirmation}
                    roles={[roles.CAN_INSERT_SMS_TOKEN]}
                  />
                  <AuthRoute
                    path="/address"
                    parent={DefaultContainer}
                    child={Address}
                    roles={[roles.CAN_CONSULT_CEP, roles.CAN_SEND_ADDRESS]}
                  />
                  <AuthRoute
                    path="/document"
                    parent={FullSizeContainer}
                    child={Document}
                    roles={[roles.CAN_SEND_DOC]}
                  />
                  <AuthRoute
                    path="/docfront"
                    parent={DefaultContainer}
                    child={DocFront}
                    roles={[roles.CAN_SEND_DOC_FRONT]}
                  />
                  <AuthRoute
                    path="/docback"
                    parent={DefaultContainer}
                    child={DocBack}
                    roles={[roles.CAN_SEND_DOC_BACK]}
                  />
                  <AuthRoute
                    path="/proof"
                    parent={DefaultContainer}
                    child={Proof}
                    roles={[roles.CAN_SEND_ADDRESS_DOC]}
                  />
                  <AuthRoute
                    path="/success"
                    parent={DefaultContainer}
                    child={Success}
                    roles={[roles.CAN_COMPLETE]}
                  />
                  {/*<AuthRoute
                    path="/getLiveness"
                    parent={LivenessContainer}
                    child={GetLiveness}
                    roles={[
                      roles.CAN_SEND_FIRST_LIVENESS_FRAME,
                      roles.CAN_RETRY_LIVENESS,
                    ]}
                  />
                  <AuthRoute
                    path="/liveness"
                    parent={FullSizeContainer}
                    child={Liveness}
                    roles={[roles.CAN_SEND_LIVENESS]}
                  />*/}
                  <AuthRoute
                    path="/liveness"
                    parent={FullSizeContainer}
                    child={Liveness}
                    roles={[
                      roles.CAN_SEND_FIRST_LIVENESS_FRAME,
                      roles.CAN_RETRY_LIVENESS,
                    ]}
                  />
                  <AuthRoute
                    path="/retryLiveness"
                    parent={LivenessContainer}
                    child={RetryLiveness}
                    roles={[roles.CAN_RETRY_LIVENESS]}
                  />
                  <AuthRoute
                    path="/quiz"
                    parent={DefaultContainer}
                    child={Quiz}
                    roles={[roles.CAN_GET_QUIZ, roles.CAN_ANSWER_QUIZ]}
                  />
                  <AuthRoute
                    path="/retryQuiz"
                    parent={DefaultContainer}
                    child={RetryQuiz}
                    roles={[roles.CAN_RETRY_QUIZ]}
                  />
                  <AuthRoute
                    path="/end"
                    parent={DefaultContainer}
                    component={End}
                  />

                  <AuthRoute
                    path="/camera"
                    parent={FullSizeContainer}
                    component={CameraException}
                  />

                  <Route component={Login} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      />
    </Router>
  )
}
