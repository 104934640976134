import styled from "styled-components"
import { Modal } from "@material-ui/core"
import { ExpansionPanel } from "@material-ui/core"

export default styled.div`
  .align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .action-icon {
    cursor: pointer;
    font-size: 1.6rem;
    padding: 2rem;
    border-radius: ${props => `${props.theme.radius}rem`};

    &:hover {
      background-color: ${props => props.theme.palette.gray[100]};
    }

    svg {
      cursor: inherit;

      &.green {
        color: ${props => props.theme.palette.green};
      }

      &.yellow {
        color: ${props => props.theme.palette.yellow};
      }

      &.red {
        color: ${props => props.theme.palette.red};
      }
    }
  }
`

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-card {
    position: absolute;
    background-color: white;
    border-radius: ${props => `${props.theme.radius}rem`};
    padding: 2rem;
    outline: none;
    box-shadow: ${props => props.theme.shadows[4]};
    font-size: 1.4rem;
    min-width: 74.5rem;
    min-height: 52.7rem;
    overflow: auto;
    max-height: 90%;

    .header {
      h2 {
        font-weight: 400;
        margin-bottom: 2rem;
        font-size: 2.4rem;
      }
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40rem;
    }

    .body {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1fr 1fr;

      .left-section {
        margin-right: 2rem;

        .image {
          height: 250px;
          margin-bottom: 2rem;
          object-fit: contain;
          border-radius: 0.8rem;
          background-color: ${props => props.theme.palette.gray[300]};
        }
      }

      .right-section {
        .name {
          font-weight: 500;
          font-size: 2.4rem;
          margin-bottom: 2rem;
        }

        .checklist-title {
          font-size: 1.6rem;
          font-weight: 500;
          color: ${props => props.theme.palette.gray[400]};
          border-bottom: 1px solid ${props => props.theme.palette.gray[500]};
          padding-bottom: 1rem;
        }

        .checklist-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 0;
          border-bottom: 1px solid ${props => props.theme.palette.gray[500]};

          b {
            font-weight: 500;
            margin-right: 2rem;
          }

          span {
            font-size: 1.2rem;
          }

          svg {
            width: 3rem;
            height: 3rem;

            &.green {
              color: ${props => props.theme.palette.green};
            }

            &.yellow {
              color: ${props => props.theme.palette.yellow};
            }

            &.red {
              color: ${props => props.theme.palette.red};
            }
          }

          .children-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 0;
            padding-left: 1rem;
            font-size: 1.2rem;

            &:not(:last-child) {
              border-bottom: 1px solid ${props => props.theme.palette.gray[500]};
            }

            svg {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .cpf-status {
            margin-left: 1rem;
            font-weight: 500;
            text-transform: uppercase;
          }

          .green {
            color: ${props => props.theme.palette.green};
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: ${props => props.theme.palette.primary};
        color: ${props => props.theme.palette.contrast};
        border: 0;
        border-radius: ${props => `${props.theme.radius}rem`};
        text-transform: uppercase;
        font-weight: 500;
        padding: 1rem 2rem;
        margin-top: 1rem;
        cursor: pointer;
        outline: none;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      margin-top: 1.5rem;
    }
  }

  .form-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 6.8rem;
    margin-top: 0.5rem;

    .title {
      width: 15rem;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`

export const CustomExpansionPanel = styled(ExpansionPanel)`
  margin-top: 1rem;

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  &.MuiExpansionPanel-root {
    width: 100%;
    margin: 0;
  }

  &.MuiExpansionPanel-root:before {
    background-color: transparent;
  }

  &.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }

  .MuiExpansionPanelSummary-root {
    padding: 0;
    min-height: 0;
  }

  .MuiExpansionPanelSummary-content {
    font-weight: bold;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .MuiExpansionPanelDetails-root {
    padding: 1rem 0;
    flex-direction: column;
  }

  .no-results {
    padding: 0 2rem;
    font-weight: bold;
    color: ${props => props.theme.palette.gray[700]};
  }

  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 4.8rem;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    padding: 0;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
  }
`
