import React from "react"

import Container from "./styles"
import { Button } from "components/Button"

export default props => {
  const handleRedirect = () => props.history.replace("/init")

  return (
    <Container>
      <h1>Alguma coisa deu errado...</h1>

      <div className="message">
        <p>Nesse momento não conseguimos aprovar seu cadastro.</p>
        <p>Por favor, tente novamente.</p>
      </div>

      <Button onClick={handleRedirect}>Tentar novamente</Button>
    </Container>
  )
}
