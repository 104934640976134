import React, { useContext, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import Container from "./styles"
import { Button } from "components/Button"
import { validationMessages } from "utils/messages"
import { Store } from "store"
import { postFile } from "api/api"
import { SET_FIELD } from "store/reducers/fieldsReducer"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { verifyTokenRole } from "config/tokenRouter"
import FileUpload from "components/FileUpload"

export default props => {
  const [state, dispatch] = useContext(Store)
  const { token, docs } = state

  const [file, setFile] = useState(null)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setError("")
    if (docs.type && file) {
      setLoading(true)
      var formData = new FormData()
      formData.append("file", file)
      formData.append("type", docs.type)
      formData.append("position", "front")

      const res = await postFile({
        url: "/document/ocr",
        token: token.value,
        data: formData
      })

      if (res.success) {
        dispatch({ type: SET_TOKEN, payload: res.token })
        return verifyTokenRole(res.token, props.history)
      }
    } else {
      setError(validationMessages.fillAllFields)
    }

    setLoading(false)
  }

  const handleChange = e => {
    dispatch({ type: SET_FIELD, payload: { field: e.target.name, value: e.target.value } })
  }

  const handleFileUpload = fileObj => {
    setFile(fileObj)
  }

  return (
    <React.Fragment>
      <Container>
        <h1>Upload de documento - Frente</h1>

        <FormControl className="type">
          <InputLabel htmlFor="type">Tipo do documento</InputLabel>
          <Select
            value={docs.type}
            onChange={handleChange}
            inputProps={{
              name: "type",
              id: "type"
            }}
          >
            <MenuItem value="cnh">CNH</MenuItem>
            <MenuItem value="rg">RG</MenuItem>
          </Select>
        </FormControl>

        <FileUpload onChange={handleFileUpload} />

        {error && <div className="error">{error}</div>}
        {loading && <div className="loading-message">Aguarde, validando documento.</div>}
        <Button onClick={handleSubmit} loading={loading}>
          Enviar
        </Button>
      </Container>
    </React.Fragment>
  )
}
