import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 100%;

  header {
    background-color: ${props => props.theme.palette.primary};
    color: ${props => props.theme.palette.contrast};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4rem;

    .menu {
      display: flex;
      align-items: center;

      .logo {
        margin: 0;
        margin-right: 2rem;
        height: 4rem;
      }

      .title {
        display: flex;
        align-items: center;
        margin: 0;

        span {
          text-transform: uppercase;
          font-size: 1.6rem;
          display: none;

          @media (min-width: ${props => props.theme.breakpoints.sm}) {
            display: inline-block;
          }
        }
      }

      .menuIcon {
        margin-left: 3rem;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    button {
      border: 0;
      background-color: transparent;
      outline: none;
      font-size: 1.6rem;
      padding: 2rem;
      cursor: pointer;
      color: ${props => props.theme.palette.contrast};

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: ${props => `${props.theme.radius}rem`};
      }
    }
  }
`
