import React, { useState, useContext, useRef } from 'react'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import Container, { CustomPopover } from './styles'
import { Button } from 'components/Button'
import { postFile } from 'api/api'
import { Store } from 'store'
import { verifyTokenRole } from 'config/tokenRouter'
import { SET_TOKEN } from 'store/reducers/tokenReducer'
import { roles } from 'config/roles'
import docFront from 'assets/doc_front.svg'
import docBack from 'assets/doc_back.svg'

const steps = ['Frente', 'Verso']

const step_messages = {
  cnh: 'da CNH',
  rg: 'do RG',
}

const supportedExts = ['jpeg', 'jpg', 'png', 'gif']

export default function ({ history }) {
  const [{ token }, dispatch] = useContext(Store)

  const imageRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [docType, setDocType] = useState(null)
  const [showDocument, setShowDocument] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectDocType = (type) => {
    setDocType(type)
    setShowDocument(true)
    handleClose()
  }

  const open = Boolean(anchorEl)

  function handleDocumentBack(e) {
    e.preventDefault()
    setShowDocument(false)
    setStepIndex(0)
  }

  function previewImage(imageRef) {
    var files = imageRef.current.files
    for (var i = 0; i < files.length; i++) {
      var file = files[i]

      const ext = file.name.substring(file.name.lastIndexOf('.') + 1)

      if (!supportedExts.includes(ext.toLowerCase())) {
        return toast.error('Formato de arquivo não suportado!')
      }

      var img = document.getElementById('preview')
      img.file = file
      var reader = new FileReader()
      reader.onload = (function (aImg) {
        return function (e) {
          aImg.src = e.target.result
        }
      })(img)
      reader.readAsDataURL(file)
    }

    setFile(files[0])
  }

  const handleSendDocument = async () => {
    if (file) {
      setLoading(true)
      var formData = new FormData()
      formData.append('file', file)

      const res = await postFile({
        url: `/utils/document/detection/${docType}/${steps[
          stepIndex
        ].toLowerCase()}`,
        token: token.value,
        data: formData,
      })

      let stepMessageIndex = stepIndex

      if (res.success) {
        const { identity } = jwtDecode(res.token)
        if (identity.role === roles.CAN_SEND_FIRST_LIVENESS_FRAME) {
          dispatch({ type: SET_TOKEN, payload: res.token })
          return verifyTokenRole(res.token, history)
        }

        setStepIndex(stepIndex + 1)
        stepMessageIndex++

        document.getElementById('preview').src = docBack
        setFile(null)
        setLoading(false)
      } else {
        setLoading(false)
      }
    }

    setLoading(false)
  }

  function removeImg() {
    if (stepIndex === 0) document.getElementById('preview').src = docFront
    else document.getElementById('preview').src = docBack
  }

  return (
    <Container>
      {!showDocument && (
        <div className="information">
          <h3 className="information__title">Dicas para envio de documentos</h3>

          <ul className="information__list">
            <li>Envie uma foto nítida, evite fotos tremidas ou borradas</li>
            <li>Para garantir a qualidade procure um local bem iluminado</li>
            <li>
              Coloque o documento sem a capinha sobre a mesa e evite inclinar o
              aparelho
            </li>
            <li>
              Enquadre seu documento totalmente na foto, conforme será
              demonstrado no próximo passo
            </li>
          </ul>

          <CustomPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <p className="popover_text">
              Escolha qual documento você deseja enviar
            </p>

            <button
              className="popover_button"
              onClick={() => selectDocType('cnh')}
            >
              CNH Carteira de Habilitação
            </button>
            <button
              className="popover_button"
              onClick={() => selectDocType('rg')}
            >
              RG Carteira de identidade
            </button>
          </CustomPopover>

          <Button onClick={handleOpen}>Enviar Documentos</Button>
        </div>
      )}

      {showDocument && (
        <React.Fragment>
          <div className="document">
            <button
              type="button"
              className="document__back"
              onClick={handleDocumentBack}
            >
              Voltar
            </button>
            <h3 className="document__side">{`${steps[stepIndex]} ${step_messages[docType]}`}</h3>
            <p className="document__info">
              Toque na imagem abaixo para enviar uma foto, caso deseje trocar de
              foto, toque novamente na imagem
            </p>
          </div>

          <div className="file_container">
            <label
              htmlFor="browse"
              className="file_container__label"
              onClick={removeImg}
            >
              <img
                id="preview"
                className="file_container__preview"
                src={docFront}
                alt="preview"
              />
            </label>
            <input
              type="file"
              id="browse"
              name="browse"
              className="file_container__input"
              onChange={() => previewImage(imageRef)}
              ref={imageRef}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>

          <div className="button_container">
            <Button onClick={handleSendDocument} loading={loading}>
              Enviar
            </Button>
          </div>
        </React.Fragment>
      )}
    </Container>
  )
}
