import React from "react"
import { Route } from "react-router-dom"

import Container from "./styles"
import logo from "assets/logo.svg"

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Container>
          <img src={logo} alt="InovaMind" className="logo" />

          <Component {...matchProps} />
        </Container>
      )}
    />
  )
}
