export function image64toCanvasRef(canvas, image64, pixelCrop) {
  return new Promise(resolve => {
    canvas.width = pixelCrop._width
    canvas.height = pixelCrop._height
    const ctx = canvas.getContext("2d")
    const image = new Image()
    image.src = image64
    image.onload = function() {
      ctx.drawImage(image, pixelCrop._x, pixelCrop._y, pixelCrop._width, pixelCrop._height, 0, 0, pixelCrop._width, pixelCrop._height)
      resolve()
    }
  })
}
