import produce from "immer"

export const SET_CALLBACK = "SET_CALLBACK"

const callbackReducer = (draft, { type, payload }) => {
  switch (type) {
    case SET_CALLBACK:
      draft["url"] = payload
      return
    default:
      return
  }
}

export default produce(callbackReducer)
