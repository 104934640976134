import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    padding: 3rem;
    margin: 2rem 0;
    background-color: ${props => props.theme.palette.gray[100]};
    border-radius: ${props => `${props.theme.radius}rem`};
  }

  p {
    font-size: 1.6rem;
    text-align: center;
    color: ${props => props.theme.palette.gray[700]};

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`
