import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Icons from 'components/Icons'

const Container = styled.div`
  height: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .icon {
    margin-bottom: 6rem;

    svg {
      width: 10rem;
      height: 10rem;
      color: ${(props) => props.theme.palette.gray[500]};
    }
  }

  .title {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }

  .info {
    font-size: 1.6rem;
    color: ${(props) => props.theme.palette.gray[500]};
    margin-bottom: 8rem;
  }

  .link {
    font-size: 2rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary};
  }
`

export default function () {
  return (
    <Container>
      <div className="icon">
        <Icons icon="cameraOff" />
      </div>
      <div>
        <p className="title">
          Não é possível acessar a câmera do seu dispositivo!
        </p>
        <p className="info">
          Para continuar o processo, autorize o acesso à câmera e tente
          novamente.
        </p>
      </div>
      <Link to="/init" className="link">
        Voltar
      </Link>
    </Container>
  )
}
