import styled from 'styled-components'
import { Popover } from '@material-ui/core'

export default styled.div`
  height: 100%;
  padding: 4rem;

  .information {
    &__title {
      font-size: 2.6rem;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 6rem;
      margin-bottom: 6rem;

      li {
        font-size: 1.6rem;
        color: ${(props) => props.theme.palette.gray[500]};
        margin-bottom: 3rem;
      }
    }
  }

  .document {
    &__back {
      background-color: transparent;
      border: 0;
      font-size: 1.6rem;
      text-transform: uppercase;
      margin-bottom: 3rem;
      font-weight: 600;
      color: ${(props) => props.theme.palette.secondary};
    }

    &__info {
      font-size: 1.6rem;
      color: ${(props) => props.theme.palette.gray[500]};
    }

    &__side {
      font-size: 2rem;
      width: 100%;
      text-align: center;
      margin-bottom: 3rem;
    }
  }

  .file_container {
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 30px;

    &__label {
      width: 60%;
    }

    &__preview {
      width: 168px;
      height: 251px;
    }

    &__input {
      display: none;
    }
  }

  .button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const CustomPopover = styled(Popover)`
  .MuiPaper-root {
    display: flex;
    flex-direction: column;

    .popover_text {
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .popover_button {
      margin: 0;
      margin-top: 1.5rem;
      background-color: ${(props) => props.theme.palette.gray[200]};
      color: ${(props) => props.theme.palette.gray[500]};
      font-size: 1.6rem;
      font-weight: 500;
      padding: 2rem;
      border: 0;
      outline: none;
      border-radius: 0.4rem;

      &:active {
        background-color: ${(props) => props.theme.palette.gray[300]};
      }
    }
  }

  .MuiPaper-rounded {
    padding: 2rem;
    font-size: 1.4rem;
  }
`
