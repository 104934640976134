import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Root from "./styles"

export const Pagination = ({ page, totalItems, totalPages, totalPerPage, onClick }) => {
  const prevOn = () => page > 0
  const nextOn = () => page + 1 < totalPages

  const calcMinResultsPerPage = () => {
    if (page === 0) return 1
    else return page + totalPerPage
  }
  const calcMaxResultsPerPage = () => {
    if (page === 0) {
      if (totalItems >= totalPerPage) return totalPerPage
      else return totalItems
    } else {
      const resultsMax = (page + 1) * totalPerPage
      if (resultsMax > totalItems) return totalItems
      else return resultsMax
    }
  }

  return (
    <Root>
      <div onClick={prevOn() ? () => onClick(page - 1) : null}>
        <FontAwesomeIcon className={`chevron ${prevOn() && "on"}`} icon={faChevronLeft} />
      </div>
      <div className="text">{`${calcMinResultsPerPage()}-${calcMaxResultsPerPage()} de ${totalItems}`}</div>
      <div onClick={nextOn() ? () => onClick(page + 1) : null}>
        <FontAwesomeIcon className={`chevron ${nextOn() && "on"}`} icon={faChevronRight} />
      </div>
    </Root>
  )
}
