import produce from "immer"

export const SET_TOKEN = "SET_TOKEN"
export const SET_ALTERNATE_TOKEN = "SET_ALTERNATE_TOKEN"

const tokenReducer = (draft, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      draft["value"] = payload
      return
    case SET_ALTERNATE_TOKEN:
      draft["alternate"] = payload
      return
    default:
      return
  }
}

export default produce(tokenReducer)
