function getEnv() {
  const baseEnv = {
    baseColor: '#545454',
    secondaryColor: '#cb184b',
    contrastColor: '#ffffff',
    secondaryContrastColor: '#ffffff',
    localStorageKey: 'onboarding_login_token',
  }

  let url = 'https://dev.fullonboarding.inovamind.app'

  if (process.env.REACT_APP_ENV === 'production') {
    url = 'https://fullonboarding-prd.inovamind.com.br'
    // } else if (process.env.REACT_APP_ENV === "staging") {
    // url = "https://fullonboarding-hml.inovamind.com.br"
    // } else {
    //   url = "https://fullonboarding-dev.inovamind.com.br"
  }

  return {
    ...baseEnv,
    url,
  }
}

export default getEnv()
