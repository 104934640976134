export const SET_STEPS = "SET_STEPS";

export default (state, { type, payload }) => {
  switch (type) {
    case SET_STEPS:
      return {
        ...state,
        steps: payload,
      };
    default:
      return state;
  }
};
