import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import {
  faCheckCircle,
  faTimesCircle,
  faStopCircle,
} from '@fortawesome/free-regular-svg-icons'
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
} from '@material-ui/core'

import Container, { ModalContainer, CustomExpansionPanel } from './styles'
import { get } from 'api/api'

export default function ({ id, token, color }) {
  const [open, setOpen] = useState(false)
  const [results, setResults] = useState({})
  const [loading, setLoading] = useState(true)

  const handleOpen = () => {
    setOpen(true)
    fetchData()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fetchData = async () => {
    const res = await get({
      url: `/dash/result/${id}`,
      token,
    })

    if (res.success) {
      setLoading(false)
      setResults(res.data)
    }
  }

  const generateLineIcon = (color) => {
    if (color === 'green')
      return <FontAwesomeIcon icon={faCheckCircle} className={color} />
    if (color === 'yellow')
      return <FontAwesomeIcon icon={faStopCircle} className={color} />
    if (color === 'red')
      return <FontAwesomeIcon icon={faTimesCircle} className={color} />
  }

  return (
    <Container>
      <div onClick={handleOpen} className="action-icon">
        {color === 'green' && (
          <FontAwesomeIcon icon={faThumbsUp} className={color} />
        )}
        {color === 'yellow' && (
          <FontAwesomeIcon icon={faThumbsUp} className={color} />
        )}
        {color === 'red' && (
          <FontAwesomeIcon icon={faThumbsDown} className={color} />
        )}
      </div>

      <ModalContainer open={open} onClose={handleClose}>
        <div className="modal-card">
          <div className="header">
            <h2>Resultados</h2>
          </div>

          {loading && (
            <div className="loading">
              <CircularProgress />
            </div>
          )}

          {!loading && results.name && (
            <React.Fragment>
              <div className="body">
                <div className="left-section">
                  <img
                    className="image"
                    src={
                      results.profile_pic
                        ? results.profile_pic
                        : './images/profile-icon.png'
                    }
                    alt=""
                  />
                </div>
                <div className="right-section">
                  <p className="name">{results.name}</p>

                  <p className="checklist-title">Checklist</p>
                  {results.description &&
                    results.description.length > 0 &&
                    results.description.map((item, i) => (
                      <div className="checklist-item" key={i}>
                        {item.children && item.children.length > 0 ? (
                          <CustomExpansionPanel>
                            <ExpansionPanelSummary>
                              <b>{item.title}</b>
                              {generateLineIcon(item.color)}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              {item.children.map((child, i) => (
                                <div className="children-item" key={i}>
                                  <div>
                                    <b>{child.title}</b>
                                  </div>
                                  {generateLineIcon(child.color)}
                                </div>
                              ))}
                            </ExpansionPanelDetails>
                          </CustomExpansionPanel>
                        ) : (
                          <React.Fragment>
                            <div>
                              <b>{item.title}</b>
                              <span>{item.value}</span>
                              {item.cpf && (
                                <span
                                  className={`cpf-status ${item.cpf.color}`}
                                >
                                  {item.cpf.status}
                                </span>
                              )}
                            </div>
                            {generateLineIcon(item.color)}
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <div className="footer">
                <button onClick={handleClose}>Fechar</button>
              </div>
            </React.Fragment>
          )}
        </div>
      </ModalContainer>
    </Container>
  )
}
