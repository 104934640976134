import styled from "styled-components"
import IconButton from "@material-ui/core/IconButton"

export default styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: auto;
  background-color: ${props => props.theme.palette.gray[100]};

  .pagination-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255, 0.6);
    color: ${props => props.theme.palette.gray[500]};
    z-index: 100;
  }

  .alignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  table {
    width: 100%;
    font-size: 1.2rem;
    border-collapse: separate;
    border-spacing: 0 1rem;
    position: relative;

    thead {
      tr {
        th {
          /* padding: 1rem;
          border-bottom: 1px solid ${props => props.theme.palette.gray[500]};
          text-align: center;
          font-weight: 500; */
          background-color: ${props => props.theme.palette.primary};
          color: ${props => props.theme.palette.contrast};
          font-weight: 500;
          font-size: 1.4rem;
          padding: 2rem;
          position: sticky;
          top: 0;

          /* &:last-child,
          :nth-last-child(2) {
            background-color: ${props => props.theme.palette.gray[300]};
          } */
        }
      }
    }

    tbody {
      tr {
        background-color: white;
        /* &:hover {
          background-color: ${props => props.theme.palette.gray[300]};
        }

        &:last-child td {
          border-bottom: 1px solid ${props => props.theme.palette.gray[500]};
        } */

        td {
          font-size: 1.2rem;
          padding: 0.8rem;

          &:first-child {
            border-left: 8px solid ${props => props.theme.palette.gray[300]};

            &.border-green {
              border-left-color: ${props => props.theme.palette.green};
            }
            &.border-yellow {
              border-left-color: ${props => props.theme.palette.yellow};
            }
            &.border-red {
              border-left-color: ${props => props.theme.palette.red};
            }
          }

          /* &:last-child,
          :nth-last-child(2) {
            background-color: ${props => props.theme.palette.gray[100]};
          } */

          & > span {
            display: flex;
          }
        }
      }
    }

    tfoot {
      tr {
        background-color: white;
        
        td {
          padding: 2rem;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: flex-end;
  }

  .loaderContainer {
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noResults {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .loader {
    color: ${props => props.theme.palette.gray[500]};
  }

  .cellIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    padding: 2rem;

    span {
      margin-top: 0.5rem;
    }
  }

  .action-icon {
    cursor: pointer;
    border-radius: ${props => `${props.theme.radius}rem`};

    &:hover {
      background-color: ${props => props.theme.palette.gray[100]};
    }

    svg {
      cursor: inherit;

      &.green {
        color: ${props => props.theme.palette.green};
      }

      &.yellow {
        color: ${props => props.theme.palette.yellow};
      }

      &.red {
        color: ${props => props.theme.palette.red};
      }
    }
  }

  .cellIcon {
    font-size: 1.6rem;
    color: ${props => props.theme.palette.gray[700]};
  }

  .cellIconMarginRight {
    margin-right: 0.5rem;
  }

  .pointer {
    cursor: pointer;
  }

  .green {
    color: ${props => props.theme.palette.green};
  }

  .validated {
    color: ${props => props.theme.palette.green};
    cursor: default;
  }

  .yellow {
    color: ${props => props.theme.palette.yellow};
    cursor: default;
  }

  .notValidated {
    color: ${props => props.theme.palette.red};
    cursor: default;
  }

  .personal-data-cell {
    & > div:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`

export const CustomIconButton = styled(IconButton)`
  padding: 0 !important;
  margin-bottom: 4px !important;
`
