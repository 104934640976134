import React from 'react'
import { Route } from 'react-router-dom'

import { Store } from 'store'

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Store.Consumer>
          {(context) => <Component {...matchProps} context={context} />}
        </Store.Consumer>
      )}
    />
  )
}
