import React, { useContext } from "react"
import { Route } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

import Root from "./styles"
import { LoginStore } from "store"
import { LOGOUT } from "store/reducers/loginReducer"
import useRouter from "utils/useRouter"
import logo from "assets/logo_white.svg"

export default ({ component: Component, ...rest }) => {
  const [, dispatch] = useContext(LoginStore)
  const router = useRouter()
  const { pathname } = router.location

  const handleLogout = () => {
    dispatch({ type: LOGOUT })
    router.history.replace("/")
  }

  const handleGoBack = () => {
    router.history.push("/init")
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Root>
          <header>
            <div className="menu">
              <div className="title">
                <img src={logo} alt="Logo" className="logo" />
                <span>Onboarding</span>
              </div>

              {pathname === "/dashboard" && (
                <button className="menuIcon" onClick={handleGoBack}>
                  <FontAwesomeIcon icon={faHome} />
                </button>
              )}
            </div>

            <button type="button" onClick={handleLogout}>
              Sair
            </button>
          </header>

          <Component {...matchProps} />
        </Root>
      )}
    />
  )
}
