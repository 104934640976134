import tokenReducer from "./tokenReducer";
import fieldsReducer from "./fieldsReducer";
import callbackReducer from "./callbackReducer";
import stepsReducer from "./stepsReducer";

const rootReducer = (state, action) => ({
  token: tokenReducer(state.token, action),
  home: fieldsReducer(state.address, action),
  address: fieldsReducer(state.address, action),
  docs: fieldsReducer(state.address, action),
  callback: callbackReducer(state.callback, action),
  steps: stepsReducer(state.steps, action),
});

export default rootReducer;
