import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .information {
    height: 100%;
    padding: 4rem;

    &__title {
      font-size: 2.6rem;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 6rem;

      li {
        font-size: 1.6rem;
        color: ${(props) => props.theme.palette.gray[500]};
        margin-bottom: 3rem;
      }
    }

    &__button_container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .cameraContainer {
    width: 100%;
    max-width: 54rem;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .mask {
      box-shadow: 0px 0px 0px 999px rgba(0, 0, 0, 0.5);
      border: 3px solid white;
      border-radius: 50%;
      width: 70%;
      height: 65%;
      margin-top: 30%;
      position: fixed;
      z-index: 1;

      &.valid {
        border-color: ${(props) => props.theme.palette.green};
      }
    }
  }

  .cameraOverlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.off {
      display: none;
    }

    .statusMessage {
      padding: 1.5rem;
      background-color: white;
      border-radius: 0.4rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      margin-top: 2rem;
    }
  }

  .loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.on {
      display: flex;
    }
  }

  .loadingLoader {
    color: ${(props) => props.theme.palette.gray[500]};
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  canvas {
    display: none;
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    transition: width 0.2s ease-in;
  }
`
