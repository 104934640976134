import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

import StyledButton from "./styles"

export const Button = ({ loading, children, ...rest }) => {
  return (
    <StyledButton className={loading ? "disabled" : ""} type="button" {...rest}>
      {loading ? <CircularProgress className="circular" /> : children}
    </StyledButton>
  )
}
