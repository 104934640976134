import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import axios from 'axios'

import Container from './styles'
import { Button } from 'components/Button'
import { MaskedInput } from 'components/MaskedInput'
import { Store, LoginStore } from 'store'
import { validationMessages } from 'utils/messages'
import { cpfValidator } from 'utils/validators'
import { verifyTokenRole } from 'config/tokenRouter'
import { SET_TOKEN } from 'store/reducers/tokenReducer'
import { baseUrl } from 'api/api'

const validationSchema = Yup.object().shape({
  cpf: Yup.string()
    .required(validationMessages.required)
    .test('valid', validationMessages.invalidCpf, (val) => {
      if (val) return cpfValidator(val)
      return false
    }),
})

const initialValues = {
  cpf: '',
}

export default (props) => {
  const [{ home }, dispatch] = useContext(Store)
  const [{ token }] = useContext(LoginStore)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    const res = await axios({
      method: 'POST',
      url: `${baseUrl}/new_register`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: home,
    })

    if (res.data) {
      dispatch({ type: SET_TOKEN, payload: res.data.token })
      verifyTokenRole(res.data.token, props.history)

      // const { token } = res.data
      // const { identity } = jwtDecode(token)

      // if (identity.role === roles.CAN_VERIFY_PHONE) {
      //   const myInsistentTimer = setIntervalAsync(async () => {
      //     const res = await axios({
      //       method: "GET",
      //       url: `${baseUrl}/sms_validate`,
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: "Bearer " + token
      //       }
      //     })

      //     if (res) {
      //       if (res.status === 200) {
      //         dispatch({ type: SET_TOKEN, payload: res.data.token })
      //         verifyTokenRole(res.data.token, props.history)
      //         clearIntervalAsync(myInsistentTimer)
      //       }
      //     }
      //   }, 1000)
      // }
    }
  }

  return (
    <React.Fragment>
      <h1>Cadastre-se</h1>

      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(form) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <Field name="cpf" label="CPF" component={MaskedInput} />
                <Button type="submit" loading={loading}>
                  Prosseguir
                </Button>
              </form>
            )
          }}
        />
      </Container>
    </React.Fragment>
  )
}
