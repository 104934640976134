import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .logo {
    width: 20rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    max-height: 15rem;
  }

  h1 {
    font-size: 2.4rem;
    margin: 2rem 0%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
  }

  h4 {
    font-size: 1.6rem;
    color: ${props => props.theme.palette.gray[500]};
    font-weight: 500;
  }
`
