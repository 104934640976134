export const cpfValidator = c => {
  const replaced = c.replace(/[^\d]/g, "")
  if (replaced.length !== 11) return false

  if (replaced === "00000000000") return false

  let r
  let s = 0

  for (let i = 1; i <= 9; i++) s += parseInt(replaced[i - 1], 10) * (11 - i)

  r = (s * 10) % 11

  if (r === 10 || r === 11) r = 0

  if (r !== parseInt(replaced[9], 10)) return false

  s = 0

  for (let i = 1; i <= 10; i++) s += parseInt(replaced[i - 1], 10) * (12 - i)

  r = (s * 10) % 11

  if (r === 10 || r === 11) r = 0

  if (r !== parseInt(replaced[10], 10)) return false

  return true
}

export const phoneValidator = c => {
  const replaced = c.replace(/[^\d]/g, "")
  if (replaced.length !== 11) return false

  if (replaced[2] !== "9") return false

  return true
}
