import styled from "styled-components"

export default styled.div`
  display: flex;
  align-items: center;

  .chevron {
    font-size: 2.2rem;
    color: #e8e8e8;

    &.on {
      color: #757575;
      cursor: pointer;
    }
  }

  .text {
    color: #7c7c7c;
    font-size: 1.4rem;
    margin: 0 10px;
  }

  .icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 0.8rem;
    font-size: 1.2rem;
  }

  .sortIcon {
    color: #afafaf;
    transition: all 0.2s;

    &.sortIconActive {
      color: ${props => props.theme.palette.primary};
      transform: scale(1.3);
    }
  }
`
