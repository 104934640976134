import produce from "immer"

export const SET_FIELD = "SET_FIELD"

const fieldsReducer = (draft, { type, payload }) => {
  switch (type) {
    case SET_FIELD:
      draft[payload.field] = payload.value
      return
    default:
      return
  }
}

export default produce(fieldsReducer)
