import React from "react"

import Container from "./styles"

export default () => {
  return (
    <React.Fragment>
      <Container>
        <h1>Seu cadastro foi recebido!</h1>

        <p>Você receberá uma mensagem nossa com o resultado da análise do seu cadastro.</p>
      </Container>
    </React.Fragment>
  )
}
