import React, { useRef, useState } from "react"

import Container from "./styles"
import { toast } from "react-toastify"
import upload from "assets/icon_upload.svg"

const supportedExts = ["jpeg", "jpg", "png", "gif"]

export default ({ onChange }) => {
  const imageRef = useRef()
  const [visible, setVisible] = useState(false)

  function previewImage(imageRef) {
    var files = imageRef.current.files
    for (var i = 0; i < files.length; i++) {
      var file = files[i]

      const ext = file.name.substring(file.name.lastIndexOf(".") + 1)

      if (!supportedExts.includes(ext.toLowerCase())) {
        return toast.error("Formato de arquivo não suportado!")
      }

      var img = document.getElementById("preview")
      img.file = file
      var reader = new FileReader()
      reader.onload = (function(aImg) {
        return function(e) {
          aImg.src = e.target.result
        }
      })(img)
      reader.readAsDataURL(file)
    }
    setVisible(true)
    onChange(files[0])
  }

  return (
    <Container>
      <div className="fileContainer">
        <img id="preview" className={visible ? "preview" : ""} src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="preview" />
        <label htmlFor="browse" className="select-label">
          {!visible && <img src={upload} alt="Upload" className="upload-image" />}
          Clique para selecionar imagem
        </label>
        <input type="file" id="browse" name="browse" className="fileInput" onChange={() => previewImage(imageRef)} ref={imageRef} accept="image/x-png,image/gif,image/jpeg" />
      </div>
    </Container>
  )
}
